import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "antd";
import CreatableSelect from 'react-select/creatable';

import { IoImages } from "react-icons/io5";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { UpdateBankDetails, UpdateAstrologerFile } from '../../../Redux/Slice';

const options = [
    { value: 'Axis Bank Ltd', label: 'Axis Bank Ltd' },
    { value: 'Bank of Baroda Ltd', label: 'Bank of Baroda Ltd' },
    { value: 'Bank of Maharashtra', label: 'Bank of Maharashtra' },
    { value: 'Bandhan Bank', label: 'Bandhan Bank' },
    { value: 'City Union Bank Ltd', label: 'City Union Bank Ltd' },
    { value: 'Canara Bank', label: 'Canara Bank' },
    { value: 'Central Bank of India', label: 'Central Bank of India' },
    { value: 'DCB Bank (Development Credit Bank)', label: 'DCB Bank (Development Credit Bank)' },
    { value: 'Federal Bank', label: 'Federal Bank' },
    { value: 'HDFC Bank Ltd', label: 'HDFC Bank Ltd' },
    { value: 'ICICI Bank Ltd', label: 'ICICI Bank Ltd' },
    { value: 'IDFC First Bank Ltd', label: 'IDFC First Bank Ltd' },
    { value: 'Indian Bank', label: 'Indian Bank' },
    { value: 'Indian Overseas Bank', label: 'Indian Overseas Bank' },
    { value: 'IDFC First Bank Ltd', label: 'IDFC First Bank Ltd' },
    { value: 'Jammu & Kashmir Bank', label: 'Jammu & Kashmir Bank' },
    { value: 'IndusInd Bank', label: 'IndusInd Bank' },
    { value: 'Karnataka Bank', label: 'Karnataka Bank' },
    { value: 'Karur Vysya Bank Ltd', label: 'Karur Vysya Bank Ltd' },
    { value: 'Kotak Mahindra Bank Ltd', label: 'Kotak Mahindra Bank Ltd' },
    { value: 'Punjab & Sind Bank', label: 'Punjab & Sind Bank' },
    { value: 'Punjab National Bank', label: 'Punjab National Bank' },
    { value: 'RBL Bank (Ratnakar Bank)', label: 'RBL Bank (Ratnakar Bank)' },
    { value: 'State Bank of India', label: 'State Bank of India' },
    { value: 'South Indian Bank Ltd', label: 'South Indian Bank Ltd' },
    { value: 'Tamilnad Mercantile Bank', label: 'Tamilnad Mercantile Bank' },
    { value: 'Union Bank of India', label: 'Union Bank of India' },
    { value: 'United Commercial Bank', label: 'United Commercial Bank' },
    { value: 'YES Bank Ltd', label: 'YES Bank Ltd' },
];

const BankDetailEdit = ({ canceledit, idProof, passBook }) => {
    const dispatch = useDispatch();
    const { Loading, AstrologerProfile } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("astrologer-auth-token");

    const [bankDetailData, setBankDetailData] = useState();
    const [idproofFile, setIdproofFile] = useState(null);
    const [passbookFile, setPassbookFile] = useState(null);

    useEffect(() => {
        setBankDetailData({
            accountholder_name: AstrologerProfile?.data?.data?.bank?.accountholder_name,
            bank_name: { value: AstrologerProfile?.data?.data?.bank?.bank_name, label: AstrologerProfile?.data?.data?.bank?.bank_name },
            accountnumber: AstrologerProfile?.data?.data?.bank?.accountnumber,
            branch: AstrologerProfile?.data?.data?.bank?.branch,
            IFSCcode: AstrologerProfile?.data?.data?.bank?.IFSCcode,
            pancard: AstrologerProfile?.data?.data?.bank?.pancard,
        })
        setIdproofFile(idProof);
        setPassbookFile(passBook);
    }, [AstrologerProfile, idProof, passBook]);

    console.log(bankDetailData);

    // useEffect(() => {
    //     if (bankDetailData?.bank_name) {
    //         setBankDetailData({
    //             ...bankDetailData, bank_name: bankName
    //         })
    //     }

    // }, [bankName, bankDetailData]);

    const updateBankDetails = () => {

        let BankDetailData = {
            accountholder_name: bankDetailData?.accountholder_name,
            bank_name: bankDetailData?.bank_name?.value,
            accountnumber: bankDetailData?.accountnumber,
            branch: bankDetailData?.branch,
            IFSCcode: bankDetailData?.IFSCcode,
            pancard: bankDetailData?.pancard,
        }
        dispatch(UpdateBankDetails({ token, BankDetailData }));

        if (idProof === null || idproofFile?.file != idProof?.file) {
            const ExistFile = idProof === null ? null : idProof;
            const fileId = idProof?.id;

            const formData = new FormData();
            formData.append('filefor', "idproof");
            formData.append('file', idproofFile);
            dispatch(UpdateAstrologerFile({ token, formData, fileId, ExistFile }));
        }
        if (passBook === null || passbookFile?.file != passBook?.file) {
            const ExistFile = passBook === null ? null : passBook;
            const fileId = passBook?.id;

            const formData = new FormData();
            formData.append('filefor', "passbook");
            formData.append('file', passbookFile);
            dispatch(UpdateAstrologerFile({ token, formData, fileId, ExistFile }));
        }
    }


    return (
        <>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col lg={10}>
                        <form action="" >
                            <div className="details__wrapper register-form mb-5">
                                <Col lg={12} className="p-3">
                                    <div className="mb-4">
                                        <h3>Bank Details</h3>
                                    </div>
                                    <div className="d-flex gap-3 bank-detail">
                                        <div className="form__item mb-4">
                                            <label>Bank account holder name</label>
                                            <input
                                                name="accountholder_name"
                                                className="px-3"
                                                value={bankDetailData?.accountholder_name}
                                                onChange={(e) => { setBankDetailData({ ...bankDetailData, accountholder_name: e.target.value }) }}
                                            />
                                        </div>
                                        <div className="form__item mb-4">
                                            <label>Bank name</label>
                                            {/* {
                                                bankDetailData?.bank_name === 'other' ?
                                                    <input name="bank_name" className="px-3" value={bankName} onChange={(e) => { setBankName(e.target.value) }} />
                                                    :
                                                    <select
                                                        value={bankDetailData?.bank_name}
                                                        onChange={(e) => { setBankDetailData({ ...bankDetailData, bank_name: e.target.value }) }}
                                                    >
                                                        {options.map((item) => (
                                                            <option value={item.value}>{item.label}</option>
                                                        ))}
                                                    </select>
                                            } */}
                                            <CreatableSelect
                                                isClearable
                                                options={options}
                                                placeholder="Select or Type your Bank"
                                                onChange={(newValue) => setBankDetailData({ ...bankDetailData, bank_name: newValue })}
                                                value={bankDetailData?.bank_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex gap-3 bank-detail">
                                        <div className="form__item mb-4">
                                            <label>Bank account number</label>
                                            <input
                                                name="accountnumber"
                                                className="px-3"
                                                value={bankDetailData?.accountnumber}
                                                onChange={(e) => { setBankDetailData({ ...bankDetailData, accountnumber: e.target.value }) }}
                                            />
                                        </div>
                                        <div className="form__item mb-4">
                                            <label>Branch Name</label>
                                            <input
                                                name="branch"
                                                className="px-3"
                                                value={bankDetailData?.branch}
                                                onChange={(e) => { setBankDetailData({ ...bankDetailData, branch: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex gap-3 bank-detail">
                                        <div className="form__item mb-4">
                                            <label>IFSC Code</label>
                                            <input
                                                name="IFSCcode"
                                                className="px-3"
                                                value={bankDetailData?.IFSCcode}
                                                onChange={(e) => { setBankDetailData({ ...bankDetailData, IFSCcode: e.target.value }) }}
                                            />
                                        </div>
                                        <div className="form__item mb-4">
                                            <label>Pan number</label>
                                            <input
                                                name="pancard"
                                                className="px-3"
                                                value={bankDetailData?.pancard}
                                                onChange={(e) => { setBankDetailData({ ...bankDetailData, pancard: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex gap-3 bank-file-upload bank-detail">
                                        <div className="form__item mb-4">
                                            <label>ID Proof <span className="text-red">*</span></label>
                                            <div className="d-flex">
                                                <input
                                                    name="name"
                                                    className="px-3"
                                                    placeholder="Driving licence/ Aadhar/Passport"
                                                    value={idproofFile?.name}
                                                    disabled
                                                />
                                                <div className="d-flex file-upload">
                                                    <input
                                                        type="file"
                                                        name="idproofFile"
                                                        onChange={(e) => { setIdproofFile(e.target.files[0]) }}
                                                        multiple
                                                    />
                                                    <div className="upload-btn d-flex justify-content-center align-items-center gap-2">
                                                        <IoImages className="file-icon" />
                                                        Upload
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__item mb-4">
                                            <label>Bank passbook</label>
                                            <div className="d-flex">
                                                <input
                                                    name="name"
                                                    className="px-3"
                                                    placeholder="Pass book/Bank statement"
                                                    value={passbookFile?.name}
                                                    disabled
                                                />
                                                <div className="d-flex file-upload">
                                                    <input
                                                        type="file"
                                                        name="passbookFile"
                                                        onChange={(e) => { setPassbookFile(e.target.files[0]) }}
                                                        multiple
                                                    />
                                                    <div className="upload-btn d-flex justify-content-center align-items-center gap-2">
                                                        <IoImages className="file-icon" />
                                                        Upload
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <div className="d-flex justify-content-center align-items-center gap-3 mx-4 p-3" >
                                    <Button
                                        type={"primary"}
                                        className="verify-btn"
                                        onClick={() => canceledit(false)}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        type={"primary"}
                                        className={Loading ? "verify-btn bg-orange disabled" : "verify-btn bg-orange"}
                                        onClick={() => updateBankDetails()}
                                    >
                                        {Loading ? <div className='loader'></div> : "Verify"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default BankDetailEdit;
